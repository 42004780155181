import React from "react";
import Hero from "./home-components/hero";
import VisiMisi from "./home-components/visions-missions";
import History from "./home-components/history";
import OurTeams from "./home-components/our-teams";
import Products1 from "./home-components/products1";
import Products2 from "./home-components/products2";
import Testimonial from "./home-components/testimonial";
import Contact from "./home-components/contact";
import OurPartners from "./home-components/our-partners";

const Home = () => {
  return (
    <div>
      <Hero />
      <VisiMisi />
      <History />
      <Products1 />
      <Products2 />
      {/* <AboutIntro /> */}
      <OurTeams />
      <Testimonial />
      <OurPartners />
      <Contact />
    </div>
  );
};

export default Home;
