import React, { Component } from "react";
import homedata from "../../data/home.json";

class OurPartners extends Component {
  render() {
    let aboutContent = homedata.ourPartners;
    let sectionHeadingInfo = aboutContent.sectionHeading;
    let aboutData = aboutContent.data;
    let publicUrl = process.env.PUBLIC_URL;

    return (
      <section id="histori" className="pt100 pb100 bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
              <div className="section-title text-center mb60">
                <h2
                  dangerouslySetInnerHTML={{ __html: sectionHeadingInfo.title }}
                ></h2>
                <hr className="lines" />
              </div>
            </div>
          </div>
          <div className="row about-boxes">
            {aboutData.map((data, i) => {
              return (
                <div key={i} className="col-lg-4 col-md-4">
                  <div className="media flex-column">
                    <div className="m-auto">
                      <img
                        src={publicUrl + data.image}
                        alt={data.partner_name}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div style={{ height: "15px " }}></div>
                    <div className="media-body m-auto">
                      <h5>{data.partner_name}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default OurPartners;
