import React, { Component } from "react";
import homedata from "../../data/home.json";

class Contact extends Component {
  render() {
    let contactInfo = homedata.contact;
    let sectionHeadingInfo = contactInfo.sectionHeading;
    let phoneInfo = contactInfo.phone;
    let websiteInfo = contactInfo.website;
    let emailInfo = contactInfo.email;
    let publicUrl = process.env.PUBLIC_URL;
    let inlineStyle = {
      backgroundImage: "url(" + publicUrl + contactInfo.bgImg + ")",
    };

    return (
      <section
        id="kontak"
        className="pt100 pb100 bg-parallax"
        style={inlineStyle}
      >
        <div className="color-overlay opacity-8"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="contact-block text-center">
                <div className="col-sm-12">
                  <h3>{sectionHeadingInfo.title}</h3>
                  <p className="subtitle">{sectionHeadingInfo.desc}</p>
                  <p className="subtitle">Telepon: {phoneInfo}</p>
                  <p className="subtitle">Website: {websiteInfo}</p>
                  <p className="subtitle">Email: {emailInfo}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
