import React, { Component } from "react";
import homedata from "../../data/home.json";

class OurTeams extends Component {
  render() {
    let ourTeamsContent = homedata.ourTeams;
    let sectionHeadingInfo = ourTeamsContent.sectionHeading;
    let workContent = ourTeamsContent.workProcess;

    return (
      <section id="tim_kami" className="pt100 pb100 bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
              <div className="section-title text-center mb60">
                <h2
                  dangerouslySetInnerHTML={{ __html: sectionHeadingInfo.title }}
                ></h2>
                <hr className="lines" />
                <p>{sectionHeadingInfo.desc}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {workContent.map((element, i) => {
              return (
                <div key={i} className="how-works-item text-center col-md-3">
                  <div
                    className="icon-outer"
                    style={{ minWidth: "5em", maxWidth: "7em" }}
                  >
                    <span>{element.title}</span>
                  </div>
                  <h4 className="title m-t-30">{element.name}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default OurTeams;
